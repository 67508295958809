// ===============================
// OLD UA BANNER TO BE REMOVED
// ===============================

.ua-redbar-v1 {
  .redbar {
    background: $red;
    position: relative;
    border: 0;
    a {
      color: ua-color('white');
      font-family: MiloWeb, Verdana, Geneva, sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      text-decoration: none;
    }
    .navbar-header {
      min-height: 59px;
    }
    ul {
      padding: 0;
    }
  }
  .navbar-nav {
    margin: 7.5px -15px;
    li:first-child {
      background: 0 0;
    }
  }
  .nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
    &:hover {
      background: $red60b;
      text-decoration: none;
      background-color: $red60b;
    }
    &:focus {
      text-decoration: none;
      background-color: $red60b;
    }
  }
  .navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 12px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    .icon-bar {
      background-color: ua-color('white');
      display: block;
      width: 22px;
      height: 2px;
      border-radius: 1px;
    }
    &:focus {
      outline: 0;
    }
    .icon-bar+.icon-bar {
      margin-top: 4px;
    }
  }
    .redbar-brand p {
        text-indent: -9999em;
        width: 253px;
        height: 16px;
        margin: 20px 0 0;
        padding: 0;
        background: linear-gradient(transparent,transparent),inline-image('../img/ua-wordmark.svg') no-repeat;
        background-size: 100%;
    }
}
@media(max-width:767px) {
    .ua-redbar-v1 {
        .navbar-nav {
            li {
                background: 0 0;
                padding-left: 0;
            }
            .open {
                .dropdown-menu {
                    position: static;
                    float: none;
                    width: auto;
                    margin-top: 0;
                    background-color: transparent;
                    border: 0;
                    box-shadow: none;
                    .dropdown-header {
                        padding: 5px 15px 5px 25px;
                    }
                }
                .dropdown-menu>li>a {
                    padding: 5px 15px 5px 25px;
                    line-height: 20px;
                    &:focus {
                        background-image: none;
                    }
                    &:hover {
                        background-image: none;
                    }
                }
            }
        }
        .redbar-brand p {
            margin: 20px 0 0 10px;
        }
    }
}
@media(max-width:360px) {
    .ua-redbar-v1 .redbar-brand p {
        background-size: 70%;
        margin: 23px 0 0 10px;
    }
}

// ===============================
// THIRD LEVEL CUSTOM SIDENAV
// ===============================

  // Stacked and nested pills

.nav-stacked-nested > li + li {
  margin-left: 0px;
}

 .nav-stacked-nested  li  a {
   font-weight: normal;
   color: $silver-dark;
   font-weight: 700;
   line-height: 1.35;
 }

  .nav-stacked-nested {
      > li {
        float: none;
        text-transform: capitalize;
        a:hover {
          background-color: hsl(188, 26%, 89%);
        }
        &.active-trail {
          > a {
            color: $river;
            background-color: #fff;
            border-left: 1px solid $river;
            padding-top: 4px;
            padding-bottom: 4px;
          }
          > a:hover {
            background-color: hsl(188, 26%, 89%);
            color: $river;
          }
          > ul {
          border-left: 1px solid $river;
            > li {
               text-transform: none;
               font-size: 90%;
              &:focus,
              &.active {
                border-left: 2px solid $river;
                transition: border .1s ease-out;
                background-color: none;
                > a {
                  color: $river;
                  color: $ash;
                  border-left: 0;
                  font-weight: bold;
                  font-weight: normal;
                }
              &:hover,
              &:focus {
                a {
                 border-left: 0;
                 font-weight: bold;
                 font-weight: normal;
               }
             }
              }
              > a {
                font-weight: normal;
                color: $ash;
                padding: 4px 4px 4px 1.5rem;
              }
            }
          }
        }
      }
  }

 .nav-stacked-nested {
     > li > ul {
     border-left: 1px solid $river;
     }
     &.right {
        text-align: right;
         > li {
             &.active-trail {
             border-right: 1px solid $river;
                 > a {
                   border-right: 0px solid $river;
                   border-left: 0px solid $river;
                 }
             }
         }
       > li > ul {
       border-right: 0px solid $river;
       border-left: 0px;
         > li {
             border-left: 0px;
             &:focus,
             &.active {
             border-right: 2px solid $river;
             border-left: 0px solid $river;
             }
         }
       }
     }
 }

 @media (min-width: 992px) {
     .nav-stacked-nested {
         > li {
             float: none;
                 > a {
                   padding: 10px 15px 10px 15px;
                   background: none;
                 &:hover,
                 &:focus {
                   background-color: hsl(188, 26%, 89%);
                   font-weight: normal;
                   font-weight: 700;
                 }
             }
             li {
               margin-top: 0px;
               margin-left: 0; // no need for this gap between nav items
             }
             &.active > a {
                 font-weight: bold;
                 text-decoration: none;
                 color: hsl(186, 89%, 24%);
                 background: none;
                 background-size: 32px 100%;
                 outline: 0;
                 background-image: none;
                 padding: 2px 11px 2px 11px;
                 &:hover,
                 &:focus {
                   background-color: hsl(188, 26%, 89%);;
                   color: hsl(186, 89%, 24%);
               }
           }
       }
        &.right {

            > li > ul {
                >li {
                  > a {
                    padding: 10px 15px 10px 32px;
                    padding-top: 2px;
                    padding-bottom: 2px;
                    &:hover,
                    &:focus {
                    }
                  }
                  &.active-trail > a {
                      background: none;
                      padding-top: 2px;
                      padding-bottom: 2px;
                    &:hover,
                    &:focus {
                      color: hsl(186, 89%, 24%);
                      background-color: hsl(188, 26%, 89%);
                      }
              }
            }
            text-align:right;
            > li {
                border-left: 0px;
                border-right: 0px solid $river;
                > a {
                  padding: 4px 1.5rem 4px 32px;
                  &:hover,
                  &:focus {
                  }
                }
                &.active > a {
                  background: none;
                }
              &.active > a:hover {
                background: hsl(188, 26%, 89%) inline-image("../img/right-sidebar-menu-chevron.svg") left center no-repeat;
              }
            }
        }

    }
  }
}

.back-to-top {
  margin-top: 10px;
}

// ===============================
// Navigation Nav Tabs Large
// ===============================
.nav-tabs-lg {
  border-bottom: 2px solid $blue;
}

.nav-tabs-lg > li {
  background-color: #E6F6F9;
  border-right:2px solid #fff;
  margin-bottom:0;
  float: left;
  &:focus, &:hover {
  background-color:#D0EEF4;
  }
}

.nav-tabs-lg > li.active {
  background-color: $blue;
}

.nav-tabs-lg > li > a {
  margin-right:2px;
  text-transform: uppercase;
  border: 0;
  padding: 1.25rem 1.33rem;
  &:focus, &:hover {
  background-color:#D0EEF4;
  }
}

.nav-tabs-lg > li.active > a {
  font-weight: 700;
  border: 0 solid #ddd;
  cursor: default;
  background-color: $blue;
  color: #fff;
  &:focus, &:hover {
    cursor: default;
    border: 0 solid #ddd;
    background-color: $blue;
    color: #fff;
  }
}

@media (max-width: 768px) {
  .nav-tabs-lg > li {
    float:none;
    border-bottom:2px solid #fff;
  }
  .nav-tabs-lg {
  border-bottom:0;
  }
  .nav-tabs-lg > li > a {
  padding: .5rem 1.33rem;
  }
}

// ===============================
// CSS CHANGES FOR MOBILE NAV
// ===============================
.navbar-toggle {
  margin-right: 0px;
  width: 100%;
  background-color: $blue;
  height: 48px;
}

.navbar-default {
  border-top: none;
  border-bottom: none;
  border-color: none;
  border-left: 0;
  border-right: 0;
}

@media (min-width: 768px) {
  .navbar-default  {
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: $silver-tint;
  }
}

.navbar-default .navbar-nav > li > a {
  color: $blue30w;
  font-weight: 700;
  letter-spacing: .03rem;
  text-transform: normal;
    }
@media (min-width: 768px) {
.navbar-nav>li>a {
        padding: 1.25rem 1.5rem;
    }
}
.navbar-nav>li>a {
  line-height: 24px;
  font-size: 19px;
  font-size: 1em;
}
.navbar-default .navbar-toggle {
  background-color: $blue;
  border-color: $blue;
}

.navbar-default .navbar-toggle:hover {
  background-color: $blue30w;
  border-color: $blue30w;
}

.navbar-default .navbar-toggle:active {
  background-color: $blue30w;
  border-color: $blue30w;
}

.navbar-default .navbar-toggle:focus {
  background-color: $blue30w;
  border-color: $blue30w;
}

.text {
  color: white;
  font-family: MiloWeb, Verdana, Geneva, sans-serif;
  font-style: normal;
  font-weight: 500;
}

.well-hollow {
  border: 1px solid $river-light;
  background-color: #fff;
  box-shadow: inset 1px 1px 5px rgba(182, 190, 193, .2);
}

// DARK TABLES
.table-dark > thead > tr > th {
  background-color: $silver-dark;
  color: #fff;
}

.table-dark tr {
  border: 1px solid $silver-tint;
}

.table-dark.table-striped > tbody > tr:nth-of-type(odd){
  background-color: $silver-tint;
}

.table-hover.table-dark.table-striped > tbody > tr:nth-of-type(odd):hover{
  background-color: $river-light;
}

.table-dark.table-striped > tbody > tr > td {
  border-bottom: none;
}

.table-dark.table-striped > tbody > tr > th {
  border-bottom: none;
}

.table-dark thead a {
  color: white;
  text-decoration: underline;
}
.table-dark a:hover {
  color: $silver-tint;
  text-decoration: underline;
}

// ===============================
//  Nested pills
// ===============================

@media (min-width: 992px) {
    .nav-stacked-nested {
        > li {
            float: none;
                > a {
                  padding: 10px 32px 10px 15px;
                  background: white inline-image('../img/left-sidebar-menu-chevron.svg') right center no-repeat;
                &:hover,
                &:focus {
                  background-color: hsl(188, 26%, 89%);
                  background-size: 32px 100%;
                }
            }
            li {
              margin-top: 0px;
              margin-left: 0; // no need for this gap between nav items
              &.active > a {
                  font-weight: bold;
                  text-decoration: none;
                  color: hsl(186, 89%, 24%);
                  background: white inline-image('../img/left-sidebar-menu-chevron.svg') right center no-repeat;
                  background-size: 32px 100%;
                  outline: 0;
                  /*border: 1px solid ua-color('river');*/
                  &:hover,
                  &:focus {
                    color: hsl(186, 89%, 24%);
                    background-color: hsl(188, 26%, 89%);
                    background-size: 32px 100%;
                }
              }
            }
            &.active > a {
                font-weight: bold;
                text-decoration: none;
                color: hsl(186, 89%, 24%);
                background: hsl(188, 26%, 89%) inline-image('../img/left-sidebar-menu-chevron.svg') right center no-repeat;
                background-size: 32px 100%;
                outline: 0;
                &:hover,
                &:focus {
                  color: hsl(186, 89%, 24%);
                  background-color: hsl(188, 26%, 89%);
                  background-size: 32px 100%;
              }
            }
        }
        &.right {
            text-align:right;
            > li {
                border-left: none;
                > a {
                  padding: 10px 15px 10px 32px;
                  background: white inline-image('../img/right-sidebar-menu-chevron.svg') left center no-repeat;
                  &:hover,
                  &:focus {
                    background: hsl(188, 26%, 89%) inline-image('../img/right-sidebar-menu-chevron.svg') left center no-repeat;
                  }
                }
                &.active > a {
                    background: hsl(188, 26%, 89%) inline-image('../img/right-sidebar-menu-chevron.svg') left center no-repeat;
                }
            }
        }

    }
}

// ===============================
//  HEADER TEXT SIZE
// ===============================

.text-size-h1 {
  font-size: $font-size-h1;
}

.text-size-h2 {
  font-size: $font-size-h2;
}

.text-size-h3 {
  font-size: $font-size-h3;
}

.text-size-h4 {
  font-size: $font-size-h4;
}

.text-size-h5 {
  font-size: $font-size-h5;
}

.text-size-h6 {
  font-size: $font-size-h6;
}

// ===============================
//  TEXT & HEADER OPTIONS
// ===============================


.text-red {
  color: $red;
}
.text-red30b {
	color: $red30b;
}
.text-blue {
  color: $blue;
}
.text-blue30w {
	color: $blue30w;
}
.text-blue60b {
	color: $blue60b;
}
.text-cactus {
  color: $cactus;
}
.text-sky {
  color: $sky;
}
.text-river {
  color: $river;
}
.text-sand {
  color: $sand;
}
.text-mesa {
  color: $mesa;
}
.text-brick {
  color: $brick;
}
// ** sage - presidential use only
.text-sage {
  color: $sage;
}
.text-silver {
  color: $silver;
}
.text-silver-dark {
  color: $silver-dark;
}
.text-ash {
  color: $ash;
}

.text-white {
  color: $white;
}

.blue30w{
    color: $blue30w;
}

.blue60b{
    color: $blue60b;
}

.sans {
    font-family: $sans-headings-font-family;
    font-weight: $sans-headings-font-weight-medium;
}

// ===============================
//  VERTICAL ALIGNMENT OF TEXT
// ===============================

.margin-zero-top {
  margin-top: 0 !important;
}

.margin-zero-bottom {
  margin-bottom: 0 !important;
}

.margin-align-top {
  margin-top: .25em;
  margin-bottom: 1.75em;
}

.margin-align-middle {
  margin-top: 1em;
  margin-bottom: 1em;
}

.margin-align-bottom {
  margin-top: 1.75em;
  margin-bottom: .25em;
}

@media (min-width:768px) {
  .margin-zero-top {
    margin-top: 0 !important;
  }

  .margin-zero-bottom {
    margin-bottom: 0 !important;
  }

  .margin-align-top {
    margin-top: .25em;
    margin-bottom: 2em;
  }

  .margin-align-middle {
    margin-top: 1.125em;
    margin-bottom: 1.125em;
  }

  .margin-align-bottom {
    margin-top: 2em;
    margin-bottom: .25em;
  }
}
// ===============================
//  background-colors
// ===============================

.bg-cochineal-red,
.bg-red {
  background-color: $red;
}

.bg-ash-tint{
  background-color: $ash-tint;
}

.bg-sky {
  background-color: $sky;
}

.bg-silver-tint {
  background-color: $silver-tint;
}
// ===============================
//  background-wrappers
// ===============================
.background-wrapper {
  padding: 3rem 0;
}
.bg-triangles-fade {
  background-image: inline-image('../img/fading-triangles.svg');
  background-repeat: repeat-x;
  background-size: auto 450px;
}

.bg-catalinas-abstract {
  background-image: inline-image('../img/catalinas-abstract.svg');
  background-repeat: repeat-x;
  background-size: auto 230px;
}

.bg-triangles-mosaic {
  background-image: inline-image('../img/mosaic-triangles.svg');
  background-repeat: repeat;
  background-size: auto 130px;
}

.bg-triangles-translucent {
    position: relative;
    overflow: hidden;
    z-index: 0;
}
.bg-triangles-translucent:after {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-image: inline-image('../img/triangles-translucent.svg');
  background-repeat: repeat;
  background-size: auto 80px;
  opacity: 0.15;
}

.background-image-wrapper div > div {
  opacity: 0;
  visibility: hidden;
}

@media (min-width: 768px) {
  .bg-triangles-translucent:after {background-size: auto 153px;}
}

@media (min-width: 768px) {
  .bg-triangles-fade {background-size: auto 530px;}
}

@media (min-width: 768px) {
  .bg-catalinas-abstract {background-size: auto 300px;}
}

@media (min-width: 768px) {
  .bg-triangles-mosaic {background-size: auto 175px;}
}

/*=====================================
BACKGROUND WRAPPER IMAGES
=====================================*/

.background-image-wrapper {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width:100%;
}
.background-image-wrapper div > div {opacity:0;} /*Don't put text over these kinds of background images.*/

@media (min-width: 768px) {
  .background-image-wrapper:before {
      content: "";
      float: initial;
      padding-bottom: initial;
  }

  .background-image-wrapper:after {
      content: "";
      display: initial;
      clear: both;
  }
}

// ==============================
// Images
// ===============================
img {
	max-width: 100%;
	height: auto;
}

img.pull-left,
figure.pull-left {
  float: left;
  margin: .25em .5em .5em 0;
}

img.pull-right,
figure.pull-right {
  float: left;
  margin: .25em 0em .5em .5em;
}


// ===============================
// Checkboxes and radio buttons
// ===============================
/*.input-group-addon {*/
  /*input {*/
    /*&[type="radio"] {*/
      /*height: 1px;*/
      /*left: 0;*/
      /*margin-left: -16px;*/
      /*margin-top: 0px;*/
      /*opacity: .01;*/
      /*outline: 0;*/
      /*position: absolute;*/
      /*top: 0px;*/
      /*width: 1px;*/

      /*+ label {*/
        /*cursor: pointer;*/
        /*font-size: 17px;*/
        /*font-weight: normal;*/
        /*line-height: 1.2;*/
        /*margin-bottom: 0;*/
        /*margin-right: 0px;*/
        /*padding: 9px 10px;*/
        /*position: relative;*/
      /*}*/

      /*+ label:before {*/
        /*border-radius: 8px;*/
      /*}*/

      /*&:focus + label:before,*/
      /*&:checked:focus + label:before {*/
        /*border: 1px ua-color('silver') solid;*/
        /*box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(113, 135, 142, .3);*/
        /*line-height: .57;*/
        /*transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;*/
      /*}*/

      /*&:checked + label:before {*/
        /*color: ua-color('silver', 'dark');*/
        /*content: '\2022';*/
        /*font-size: 29px;*/
        /*line-height: .589;*/
        /*padding-left: 1px;*/
        /*text-align: center;*/
      /*}*/
    /*}*/

    /*&:checked {*/
      /*outline: 0;*/
    /*}*/

    /*+ label:before {*/
      /*background-color: ua-color('silver', 'tint');*/
      /*box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, .2), 0 1px 0 0 rgba(255, 255, 255, .8);*/
      /*content: '';*/
      /*display: inline-block;*/
      /*font-size: 1.6em;*/
      /*height: 16px;*/
      /*left: 0;*/
      /*line-height: 1.1;*/
      /*margin-right: 0;*/
      /*position: absolute;*/
      /*text-align: center;*/
      /*top: 0;*/
      /*width: 16px;*/
    /*}*/

    /*&[type='checkbox'] {*/
      /*height: 1px;*/
      /*left: 0;*/
      /*margin-left: -16px;*/
      /*margin-top: 9px;*/
      /*opacity: .01;*/
      /*outline: 0;*/
      /*position: absolute;*/
      /*top: 0;*/
      /*width: 0;*/

      /*&:focus,*/
      /*&:checked:focus {*/
        /*outline: 0 solid #141819;*/
        /*outline-offset: 7px;*/
      /*}*/

      /*&:focus + label:before,*/
      /*&:checked:focus + label:before {*/
        /*border: 1px #8f8f8f solid;*/
        /*box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(113, 135, 142, .3);*/
        /*line-height: 1.1;*/
        /*transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;*/
      /*}*/
    /*}*/

    /*+ label {*/
      /*cursor: pointer;*/
      /*font-size: 17px;*/
      /*font-weight: normal;*/
      /*line-height: 1.3;*/
      /*margin-bottom: 0;*/
      /*margin-right: 0px;*/
      /*padding: 8px 0 10px 20px;*/
      /*position: relative;*/
    /*}*/

    /*&[type="radio"]:focus {*/
      /*outline: 0 solid #141819;*/
      /*outline-offset: 7px;*/
    /*}*/

    /*&[type="radio"]:checked:focus {*/
      /*outline: 0 solid #141819;*/
      /*outline-offset: 7px;*/
    /*}*/

    /*&[type="checkbox"]:checked {*/
      /*color: ua-color('silver', 'dark');*/
      /*content: '\2713';*/
      /*font-size: 16px;*/
      /*line-height: 1;*/
      /*outline: 0;*/
      /*text-align: center;*/
      /*text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);*/

      /*+ label:before {*/
        /*color: ua-color('silver', 'dark');*/
        /*content: '\2713';*/
        /*font-size: 16px;*/
        /*line-height: 1;*/
        /*outline: 0;*/
        /*text-align: center;*/
        /*text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);*/
      /*}*/
    /*}*/

    /*&[type="radio"][disabled],*/
    /*&[type="checkbox"][disabled],*/
    /*&[type="radio"].disabled,*/
    /*&[type="checkbox"].disabled {*/
      /*cursor: not-allowed;*/
    /*}*/
  /*}*/
/*}*/

/*.input-group {*/
  /*input {*/
    /*&[type="radio"],*/
    /*&[type="checkbox"] {*/
        /*position: absolute;*/
        /*left: 43px;*/
        /*top: 8px;*/
    /*}*/
  /*}*/
/*}*/

/*input {*/
  /*&[type="radio"] {*/
    /*height: 1px;*/
    /*margin-left: -16px;*/
    /*margin-top: 9px;*/
    /*opacity: .01;*/
    /*outline: 0;*/
    /*width: 1px;*/

    /*+ label {*/
      /*cursor: pointer;*/
      /*font-size: 17px;*/
      /*font-weight: normal;*/
      /*line-height: 1.2;*/
      /*margin-bottom: 0;*/
      /*margin-right: 15px;*/
      /*padding: 9px 25px;*/
      /*position: relative;*/
    /*}*/

    /*+ label:before {*/
      /*border-radius: 8px;*/
    /*}*/

    /*&:focus + label:before,*/
    /*&:checked:focus + label:before {*/
      /*border: 1px ua-color('silver') solid;*/
      /*box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(113, 135, 142, .3);*/
      /*line-height: .57;*/
      /*transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;*/
    /*}*/

    /*&:checked + label:before {*/
      /*color: ua-color('silver', 'dark');*/
      /*content: '\2022';*/
      /*font-size: 29px;*/
      /*line-height: .589;*/
      /*padding-left: 1px;*/
      /*text-align: center;*/
    /*}*/
  /*}*/

  /*&:checked {*/
    /*outline: 0;*/
  /*}*/

  /*+ label:before {*/
    /*background-color: ua-color('silver', 'tint');*/
    /*box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, .2), 0 1px 0 0 rgba(255, 255, 255, .8);*/
    /*content: '';*/
    /*display: inline-block;*/
    /*font-size: 1.6em;*/
    /*height: 16px;*/
    /*left: 0;*/
    /*line-height: 1.1;*/
    /*margin-right: 10px;*/
    /*position: absolute;*/
    /*text-align: center;*/
    /*top: 10px;*/
    /*width: 16px;*/
  /*}*/

  /*&[type='checkbox'] {*/
    /*height: 1px;*/
    /*margin-left: -16px;*/
    /*margin-top: 9px;*/
    /*opacity: .01;*/
    /*outline: 0;*/
    /*width: 1px;*/

    /*&:focus,*/
    /*&:checked:focus {*/
      /*outline: 0 solid #141819;*/
      /*outline-offset: 7px;*/
    /*}*/

    /*&:focus + label:before,*/
    /*&:checked:focus + label:before {*/
      /*border: 1px #8f8f8f solid;*/
      /*box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(113, 135, 142, .3);*/
      /*line-height: 1.1;*/
      /*transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;*/
    /*}*/
  /*}*/

  /*+ label {*/
    /*cursor: pointer;*/
    /*font-size: 17px;*/
    /*font-weight: normal;*/
    /*line-height: 1.3;*/
    /*margin-bottom: 0;*/
    /*margin-right: 15px;*/
    /*padding: 8px 0 10px 25px;*/
    /*position: relative;*/
  /*}*/

  /*&[type="radio"]:focus {*/
    /*outline: 0 solid #141819;*/
    /*outline-offset: 7px;*/
  /*}*/

  /*&[type="radio"]:checked:focus {*/
    /*outline: 0 solid #141819;*/
    /*outline-offset: 7px;*/
  /*}*/

  /*&[type="checkbox"]:checked {*/
    /*color: ua-color('silver', 'dark');*/
    /*content: '\2713';*/
    /*font-size: 16px;*/
    /*line-height: 1;*/
    /*outline: 0;*/
    /*text-align: center;*/
    /*text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);*/

    /*+ label:before {*/
      /*color: ua-color('silver', 'dark');*/
      /*content: '\2713';*/
      /*font-size: 16px;*/
      /*line-height: 1;*/
      /*outline: 0;*/
      /*text-align: center;*/
      /*text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);*/
    /*}*/
  /*}*/

  /*&[type="radio"][disabled],*/
  /*&[type="checkbox"][disabled],*/
  /*&[type="radio"].disabled,*/
  /*&[type="checkbox"].disabled {*/
    /*cursor: not-allowed;*/
  /*}*/
/*}*/

/*.radio-inline input[type='radio'],*/
/*.checkbox-inline input[type='checkbox'] {*/
  /*height: 1px;*/
  /*left: 43px;*/
  /*margin-left: -16px;*/
  /*margin-top: 9px;*/
  /*opacity: .01;*/
  /*outline: 0;*/
  /*position: absolute;*/
  /*top: 8px;*/
  /*width: 1px;*/
/*}*/

/*.radio:hover label:before {*/
  /*border: 1px #909090 solid;*/
  /*box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(113, 135, 142, .3);*/
  /*line-height: .57;*/
  /*transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;*/
/*}*/


/*.checkbox:hover label:before {*/
  /*border: 1px #8f8f8f solid;*/
  /*box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(113, 135, 142, .3);*/
  /*line-height: 1.1;*/
  /*transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;*/
/*}*/

/*label {*/
  /*color: ua-color('silver', 'dark');*/
  /*display: block;*/
  /*font-size: 1em;*/
  /*font-weight: bold;*/
  /*margin-bottom: 5px;*/
  /*padding: 20px 0 0;*/
/*}*/

/*.form-required {*/
  /*color: #ad0521;*/
/*}*/

/*form {*/
  /*margin: 20px 0;*/
  /*position: relative;*/

  /*&:first-child {*/
    /*margin-top: 0;*/
  /*}*/
/*}*/

/*.radio + .radio,*/
/*.checkbox + .checkbox {*/
  /*margin-top: -5px;*/
/*}*/

/*.radio-inline,*/
/*.checkbox-inline {*/
  /*cursor: pointer;*/
  /*display: inline-block;*/
  /*font-weight: normal;*/
  /*margin-bottom: 0;*/
  /*margin-top: 0;*/
  /*padding-left: 0;*/
  /*vertical-align: middle;*/
/*}*/

/*.radio-inline + .radio-inline,*/
/*.checkbox-inline + .checkbox-inline {*/
  /*margin-right: 10px;*/
  /*margin-top: 0;*/
/*}*/

/*fieldset[disabled] input {*/
  /*&[type="radio"],*/
  /*&[type="checkbox"] {*/
    /*cursor: not-allowed;*/
  /*}*/
/*}*/

/*.radio-inline.disabled,*/
/*.checkbox-inline.disabled {*/
  /*cursor: not-allowed;*/
/*}*/

/*fieldset[disabled] {*/
  /*.radio-inline,*/
  /*.checkbox-inline {*/
    /*cursor: not-allowed;*/
  /*}*/
/*}*/

/*.radio.disabled label,*/
/*.checkbox.disabled label {*/
  /*cursor: not-allowed;*/
/*}*/

/*fieldset[disabled] {*/
  /*.radio label,*/
  /*.checkbox label {*/
    /*cursor: not-allowed;*/
  /*}*/
/*}*/

// ===============================
//  List Group Flush for use in cards
// ===============================

.list-group-flush {
  margin:-1px;
}
// ===============================
//  Utility Links
// ===============================

#utility_links {
  display: block;
  margin: 1rem 0 1.5rem 0;
  background-color: transparent;
  ul {
    display: block;
    padding: 0;
    margin: 0;
    border: 0 none;
    text-align: center;
    li {
      list-style: none;
      display: inline-block;
      padding: 0 0.6rem;
      text-align: center;
      a {
        color: $silver-dark;
        border-bottom: 2px solid transparent;
        font-family: MiloWeb-Text, sans-serif;
        font-size: 1rem;
        font-weight: normal;
        line-height: 2;
        text-decoration: none;
        text-transform: none;
        &:hover {
          text-decoration: none;
          border-bottom: 2px solid $silver-dark;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  #utility_links ul {
    text-align: right;
    li {
      text-align: left;
    }
  }
}

// External Link Icon
span.ext,
a.ext {
  position: relative;
  display: inline-block;
  padding-right: .6em;
}

span.ext:after,
a.ext:after {
  border-bottom: 0.3125em solid transparent;
  border-left: 0.3125em solid transparent;
  border-right: 0.3125em solid $red30b;
  border-top: 0.3125em solid $red30b;
  content: '';
  display: inline-block;
  height: 0px;
  position: absolute;
  top: .38em;
  width: 0px;
}

a.ext.ext-blue:after {
border-color: $blue $blue transparent transparent;
}
a.ext.ext-white:after {
border-color: white white transparent transparent;
}

// ===============================
//  Link containers
// ===============================

span.link-container,
a.link-container {
    color: inherit;
    display: block;
    font-weight: inherit;
}

span.link-container:hover,
span.link-container:focus,
a.link-container:hover,
a.link-container:focus {
    background-color: hsla(180,10%,94%,1);
    outline: none;
    text-decoration: none;
    color: inherit;
}

span.height-100,
a.height-100 {
    display: block;
    height: 100%;
}

// ===============================
//  Buttons
// ===============================

.btn-arrow:after {
    content: "\00a0»";
}
.btn-hollow,
.btn-hollow-default {
    @include button-variant($btn-hollow-default-color, $btn-hollow-default-bg, $btn-hollow-default-border, $btn-hollow-default-action-color, white);
}
.btn-hollow-primary {
    @include button-variant($btn-hollow-primary-color, $btn-hollow-primary-bg, $btn-hollow-primary-border, $btn-hollow-primary-action-color, white);
}
.btn-hollow-reverse{
    @include button-variant($btn-hollow-reverse-color, $btn-hollow-reverse-bg, $btn-hollow-reverse-border, white, $silver);
}

// River appears as river
.btn-river {
    @include button-variant($btn-river-color, $btn-river-bg, $btn-river-border, $btn-river-action-color, $btn-river-color);
}

// ===============================
//  Header Search Box
// ===============================

#header_site {
  #search {
    float: right;
    .form-item {
      display: inline;
      input {
        border-collapse: collapse;
        width: 13.8rem;
        font-family: MiloWeb, sans-serif;
        font-weight: normal;
        font-size: 1.125rem;
        line-height: 1em;
        border: 0;
        background-color: $silver-tint;
        color: $ash;
        padding: .5rem;
        outline: 0;
      }
    }

    .form-actions {
      display: inline;
      input[type="submit"] {
        text-indent: -9999em;
        //background: url("../img/search-icon.png");
        width: 40px;
        height: 40px;
        width: 2.5rem;
        height: 2.5rem;
        background-size: 24px 24px;
        background-size: 1.5rem 1.5rem;
        border: 0;
        background-repeat: no-repeat;
        background-position: 9px 7px;
        outline: 0;
      }
    }

    form {
      border: 1px solid $river-light;
      background-color: $silver-tint;
      color: $ash;
      width: 272px;
      width: 17rem;
      margin: 0 auto 24px;
      margin: 0 auto 1.5rem;
    }
  }

  #site_identifier a {
    float: left;
    margin: 1.5rem 0;
    padding: 0;
    width: 100%;
    text-align: center;

    img {
      width: 80%;
      max-width: 400px;
      height: auto;
      margin: 0 auto;
    }
  }
}

@media (max-width: 767px) {
  #header_site #search {
    width: 100%;
    float: none;
    clear: both;
  }
}

// ===============================
//  Site Identifier // Logo
// ===============================

@media (min-width: 768px) {
  #header_site #site_identifier a {
    float: left;
    margin: 2.5rem 0;
    padding: 0;
    width: auto;
    img {
      width: 100%;
    }
  }
}

// ===============================
//  UA Banner
// ===============================

.arizona-logo {
  display: inline-block;
  height: 20px;
  margin: 19px 0 20px;
  max-width: 80%;
  float: left;
  width: 276px;
  padding: 0 0 0 .6rem;
}


// ===============================
//  UA Footer
// ===============================

#footer_site {
  background: $brick-light;
  margin-top: 2.5rem;
  padding: 2.5rem 0;
  #footer_logo img {
    height: auto;
    margin: 0 auto 1rem;
    max-width: 360px;
    width: 80%;
  }
  ul {
    display: inline-block;
    margin: 0;
    padding: 1rem 0 0;
    > li {
      border-right: 1px solid $river-light;
      display: inline-block;
      list-style: none;
      padding: 0 .75rem;
      &.last {
        border-right: 0;
      }
    }
  }
  a {
    color: $ash;
    text-decoration: underline;
  }
  #footer_copyright {
   clear: both;
    float: left;
    width: 100%;
  }
}

@media (min-width: 992px) {
  #footer_site #footer_logo img {
    margin: 0;
  }
}

// ================================
//  responsive alignment classes
// ===============================

.text-left-not-xs,
.text-left-not-sm,
.text-left-not-md,
.text-left-not-lg {
  text-align: left;
}

.text-center-not-xs,
.text-center-not-sm,
.text-center-not-md,
.text-center-not-lg {
  text-align: center;
}

.text-right-not-xs,
.text-right-not-sm,
.text-right-not-md,
.text-right-not-lg {
  text-align: right;
}

.text-justify-not-xs,
.text-justify-not-sm,
.text-justify-not-md,
.text-justify-not-lg {
  text-align: justify;
}

@media (max-width: 767px) {

  .text-left-not-xs,
  .text-center-not-xs,
  .text-right-not-xs,
  .text-justify-not-xs {
    text-align: inherit;
  }

  .text-left-xs {
    text-align: left;
  }

  .text-center-xs {
    text-align: center;
  }

  .text-right-xs {
    text-align: right;
  }

  .text-justify-xs {
    text-align: justify;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .text-left-not-sm,
  .text-center-not-sm,
  .text-right-not-sm,
  .text-justify-not-sm {
    text-align: inherit;
  }

  .text-left-sm {
    text-align: left;
  }

  .text-center-sm {
    text-align: center;
  }

  .text-right-sm {
    text-align: right;
  }

  .text-justify-sm {
    text-align: justify;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {

  .text-left-not-md,
  .text-center-not-md,
  .text-right-not-md,
  .text-justify-not-md {
    text-align: inherit;
  }

  .text-left-md {
    text-align: left;
  }

  .text-center-md {
    text-align: center;
  }

  .text-right-md {
    text-align: right;
  }

  .text-justify-md {
    text-align: justify;
  }
}

@media (min-width: 1200px) {

  .text-left-not-lg,
  .text-center-not-lg,
  .text-right-not-lg,
  .text-justify-not-lg {
    text-align: inherit;
  }

  .text-left-lg {
    text-align: left;
  }

  .text-center-lg {
    text-align: center;
  }

  .text-right-lg {
    text-align: right;
  }

  .text-justify-lg {
    text-align: justify;
  }
}

.label-muted {
  color: $label-muted-color;
  @include label-variant($label-muted-bg);
}

// ================================
//  Row buffer
// ===============================
.bottom-buffer-1 {
  margin-bottom:1px;
}

.bottom-buffer-5 {
  margin-bottom: 5px;
}

.bottom-buffer-10 {
  margin-bottom: 10px;
}

.bottom-buffer-15 {
  margin-bottom: 15px;
}

.bottom-buffer-20 {
  margin-bottom: 20px;
}

.bottom-buffer-30 {
  margin-bottom: 30px;
}
