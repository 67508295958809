// Inspired by https://github.com/minusfive/sass-material-colors

$ua-colors: (

    // ==========================================================================
    //    primary ua red and blue colors
    // ==========================================================================

    'red': (
        '60w'     : #dea094,
        '30w'     : #cc665e,
        'default' : #ab0520,
        '30b'     : #8f1124,
        '60b'     : #63000b
    ),
    'blue': (
        '60w'     : #7b89ae,
        '30w'     : #395180,
        'default' : #0c234b,
        '30b'     : #00194a,
        '60b'     : #000136
    ),

    // ==========================================================================
    //    secondary palette
    // ==========================================================================

    'cactus': (
        'default' : #5c8727,
        'light'   : #cfe0d8
        ),
    'sky': (
        'default' : #84d2e2,
        'light'   : #c8d9d8
        ),
    'river': (
        'default' : #076873,
        'light'   : #b6bec1
        ),
    'sand': (
        'default' : #f19e1f,
        'light'   : #fce1b6
        ),
    'mesa': (
        'default' : #b75527,
        'light'   : #fae7d8
        ),
    'brick': (
        'default' : #4a3027,
        'light'   : #e6e3d9
        ),

    // ==========================================================================
    //    legacy palette
    // ==========================================================================

    'sage': (
        'default': #4a634e
        ),
    'silver': (
        'tint'   : #eef1f1,
        'default': #6f868d,
        'dark'   : #49595e
        ),
    'ash': (
        'tint'   : #f7f9f9,
        'default': #333
        ),
    'white': (
        'default': #fff
        ),
);

