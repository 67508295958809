/* ==========================================================================
 * // Core variables and mixins
 * ========================================================================== */

/* @import "bootstrap/variables";
 * ========================================================================== */

/* @import "bootstrap/mixins";
 * ========================================================================== */

/* Buttons */
@mixin button-variant($color, $background, $border, $action-bg, $action-color) {
  color: $color;
  background-color: $background;
  border-color: $border;
  border-width: 2px;
  background-clip: border-box;
  transition: all .1s ease-in-out;

  &:focus,
  &.focus {
    color: $action-color;
    background-color: $action-bg;
        border-color: $action-bg;
  }
  &:hover {
    color: $action-color;
    background-color: $action-bg;
        border-color: $action-bg;
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $action-color;
    background-color: $action-bg;
        border-color: $action-bg;

    &:hover,
    &:focus,
    &.focus {
      color: $action-color;
      background-color: $action-bg;
          border-color: $action-bg;
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background: $gray-light;
          border-color: $gray-light;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}

/* Forms */
@mixin form-control-focus($color: $input-border-focus) {
    &:focus {
        border-color: #141819;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(113,135,142,.3);
    }
}

/* Text-Emphasis */
@mixin text-emphasis-variant($parent, $color) {
    a#{$parent}:hover,
    a#{$parent}:focus {
        color: $color;
    }
}

/* ==========================================================================
 * // Reset and dependencies
 * ========================================================================== */

/* @import "bootstrap/normalize";
 * ========================================================================== */

/* @import "bootstrap/print";
 * ========================================================================== */

/* @import "bootstrap/glyphicons";
 * ========================================================================== */

/* ==========================================================================
 * // Core CSS
 * ========================================================================== */

/* @import "bootstrap/scaffolding";
 * ========================================================================== */
html {
    font-size: 16px;
}
body {
    font-family: $font-family-text;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0,0,0,.004);
}
a {
    font-weight:bold;
    transition: all .1s ease-in-out;
}

/* @import "bootstrap/type";
 * ========================================================================== */

h1, h2,
.h1, .h2{
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;

    small,
    .small {
        font-weight: normal;
        line-height: 1;
        color: $headings-small-color;
    }
}
h3, h4, h5, h6,
.h3, .h4, .h5, .h6 {
    font-family: $headings-font-family-medium;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;

    small,
    .small {
        font-weight: normal;
        line-height: 1;
        color: $headings-small-color;
    }
}

h1, .h1,
h2, .h2,
h3, .h3 {
    margin-top: 1em;
    margin-bottom: .5em;
}

h1, .h1 {
  letter-spacing: -0.00625em;
}

strong, bold{
    font-weight:bold;
}

.page-header {
  margin: 3rem 0 2rem;
  line-height: 1.2;

}

ul.triangle {
    overflow:hidden;
    > li,
    > ul li {
        padding-left: 0;
        position: relative;
        list-style: none;
        &:before {
            position: absolute;
            top: 8px;
            left: -1em;
            width: 0;
            height: 0;
            border-top: 6px solid $blue;
            border-left: 6px solid transparent;
            content: '';
        }
    }
}

/* @import "bootstrap/code";
 * ========================================================================== */
pre {
    border: 1px solid hsl(0, 0%, 87%) !important;
}

/* @import "bootstrap/grid";
 * ========================================================================== */

// Sticky Footer
//
// wrap page elements in following classes

html.sticky-footer {
    height: 100%;

    body {
        height: 100%;
        display: table;
        width: 100%;
        margin:0;
    }

    .page-row {
        display: table-row;
        min-height: 1px;
    }

    .page-row-expanded {
        height: 100%;
    }

    .page-row-padding-top:before, .page-row-padding-bottom:after {
        content: '';
        height: 1em;
        display: block;
    }
}

/* @import "bootstrap/tables";
 * ========================================================================== */
// Baseline styles

table {
  width: 100%;
  max-width: 100%;
  margin-bottom: $line-height-computed;
  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: $table-cell-padding;
        line-height: $line-height-base;
        vertical-align: top;
        border-top: 1px solid $table-border-color;
      }
    }
  }
  // Bottom align for column headings
  > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid $table-border-color;
  }
  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }
  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 2px solid $table-border-color;
  }

  // Nesting
  .table {
    background-color: $body-bg;
  }
}
table,
.table {
    > thead > tr > th {
    background-color: $table-header-bg-color;
    color:$table-header-color;
    text-transform: uppercase;
    }
}

.table-striped > tbody > tr:nth-of-type(odd){
  background-color: transparent;
}

.table-striped > tbody > tr > th,
.table-striped > tbody > tr > td  {
  border-bottom: 1px solid $river-light;
}

.table-striped > tbody > tr:last-child > td,
.table-striped > tbody > tr:last-child > th {
    border-bottom: none;
}


.table-hover {
    > tbody > tr:hover {
        color: #000;
        background-color: $river-light;
        cursor: pointer;
    }
}

/* @import "bootstrap/forms";
 * ========================================================================== */
label {
    /*display: inline-block;*/
    /*max-width: 100%; // Force IE8 to wrap long content (see https://github.com/twbs/bootstrap/issues/13141)*/
    /*margin-bottom: 5px;*/
    /*padding: 1em 0 0;*/
    color: $silver-dark;
    /*font-weight: 700;*/
    /*font-size: 1em;*/
}
.label {
    border-radius: 0em;
}
.search {
    border: 1px solid $river-light;
    background-color: $silver-tint;
    color: $ash;
    margin: 0 auto 24px;
    margin: 0 auto 1.5rem;

    .form-control {
        background-color: $silver-tint;
        border: 0;
        border-collapse: collapse;
        box-shadow: none;
        color: $ash;
        font-family: MiloWeb, sans-serif;
        font-size: 1.125rem;
        font-weight: normal;
        line-height: 1em;
        outline: 0;
        padding: .5rem;
        width: 13.8rem;
    }

    .btn-default,
    .btn {
        text-indent: -9999em;
        background: inline-image('../img/search.svg') right center no-repeat;
        /*background: url("../img/search-icon.png");*/
        width: 40px;
        height: 40px;
        width: 2.5rem;
        height: 2.5rem;
        background-size: 24px 24px;
        background-size: 1.5rem 1.5rem;
        border: 0;
        background-repeat: no-repeat;
        background-position: 9px 7px;
        outline: 0;

        &.active,
        &:active,
        &:focus,
        &:hover {
            background: inline-image('../img/search.svg') right center no-repeat;
            background-color:transparent;
            background-position: 8px 7px;
            background-size: 24px 24px;
            background-size: 1.5rem 1.5rem;
            transition: background-position .5s ease-in-out;
        }
    }
}

select.form-control, select {
    border: 0;
    outline: 1px solid $input-border;
    background-color: white;
    border-radius: 0;
}

/* @import "bootstrap/buttons";
 * ========================================================================== */
.btn {
    background-clip: border-box;
    font-weight: 700;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    letter-spacing: .04em;
    text-transform: uppercase;
}

.btn-red,
.btn-default {
    @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border, $btn-default-action-color, $btn-default-color);
}
.btn-blue,
.btn-primary {
    @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border, $btn-primary-action-color, $btn-primary-color);
}
// Success appears as cactus
.btn-cactus,
.btn-success {
    @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border, $btn-success-action-color, $btn-success-color);
}
// Info appears as sky
.btn-sky,
.btn-info {
    @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border, $btn-info-action-color, $btn-info-color);
}
// Warning appears as sand
.btn-sand,
.btn-warning {
    @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border, $btn-warning-action-color, $btn-warning-color);
}
// Danger and error appear as mesa
.btn-mesa,
.btn-danger {
    @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border, $btn-danger-action-color, $btn-danger-color);
}

.btn-link {
    font-weight: 700;
}

/* ==========================================================================
 * // Components
 * ========================================================================== */

/* @import "bootstrap/component-animations";
 * ========================================================================== */

/* @import "bootstrap/dropdowns";
 * ========================================================================== */
@media (min-width: 768px) {
    .dropdown:hover .dropdown-menu {
        display:block;
    }
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: $zindex-dropdown;
    display: none; // none by default, but block on "open" of the menu
    float: left;
    min-width: 160px;
    margin: 0; // override default ul
    list-style: none;
    padding: 5px 0 5px 0;
    font-size: $font-size-base;
    text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
    background-color: $dropdown-bg;
    border: 0; // IE8 fallback
    border: 0;
    border-radius: $border-radius-base;
    @include box-shadow(0 6px 12px rgba(0,0,0,.175));
    background-clip: barder-box;

    // Aligns the dropdown menu to right
    //
    // Deprecated as of 3.1.0 in favor of `.dropdown-menu-[dir]`
    &.pull-right,
    &.dropdown-menu-right {
        right: 0;
        left: auto;

        @media (min-width: 768px) {
            &:before {
                right: 8.9%;
                left: auto;
            }
        }
    }

    @media (min-width: 768px) {
        &:before {
            position: absolute;
            top: 0px;
            left: 8.9%;
            width: 0;
            height: 0;
            border-top: 8px solid white;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
            content: '';
        }
    }

    // Dividers (basically an hr) within the dropdown
    .divider {
        @include nav-divider($dropdown-divider-bg);
    }

    // Links within the dropdown menu
    > li > a {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: bold;
        line-height: $line-height-base;
        color: $dropdown-link-color;
        white-space: nowrap; // prevent links from randomly breaking onto new lines
    }
}

@media (min-width: 768px) {
    .dropdown-menu {
        padding: 20px 0 5px 0;
    }
}
.dropup,
.navbar-fixed-bottom .dropdown {
    // Reverse the caret
    .caret {
        border-top: 0;
        border-bottom: $caret-width-base dashed;
        border-bottom: $caret-width-base solid \9; // IE8
        content: "";
    }


    // Different positioning for bottom up menu
    .dropdown-menu {
        top: auto;
        bottom: 100%;
        margin-bottom: 0px;
        padding: 5px 0 20px 0;

        &:before {
            left: 8.9%;
            top: auto;
            bottom: 0;
            border-top: 0;
            border-bottom: 8px solid white;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
            content: '';
        }
    }
}

/* @import "bootstrap/button-groups";
 * ========================================================================== */
.btn .caret {
    margin-left: 2px;
}

/* @import "bootstrap/input-groups";
 * ========================================================================== */
.input-group-btn {
    position: relative;
    // Jankily prevent input button groups from wrapping with `white-space` and
    // `font-size` in combination with `inline-block` on buttons.
    white-space: nowrap;

    // Negative margin for spacing, position for bringing hovered/focused/actived
    // element above the siblings.
    > .btn {
        position: relative;
        + .btn {
            margin-left: -2px;
        }
        // Bring the "active" button to the front
        &:hover,
        &:focus,
        &:active {
            z-index: 2;
        }
    }

    // Negative margin to only have a 1px border between the two
    &:first-child {
        > .btn,
        > .btn-group {
            margin-right: -2px;
            margin-top: 0;
            padding: 5px 8px;
        }
    }
    &:last-child {
        > .btn,
        > .btn-group {
            z-index: 2;
            margin-left: -2px;
            margin-top: 0;
            padding: 5px 8px;
        }
    }
}

/* @import "bootstrap/navs";
 * ========================================================================== */
.nav > li > a {
    color: $blue30w;
    &:hover {
        color: $blue30w;
        background-color: $silver-tint;
    }
}

.nav-tabs > li.active > a {
  font-weight: 700;
  border: 0 solid $brick-light;
  cursor: default;
  background-color: $blue;
  color: #fff;
  border-bottom: 0 solid $blue;
  &:focus, &:hover {
    font-weight: 700;
    border: 0 solid $brick-light;
    cursor: default;
    background-color: $blue;
    color: #fff;
    border-bottom: 0 solid $blue;
  }
}
// Pills
// -------------------------
.nav-pills {
  > li {
    float: left;

    // Links rendered as pills
    > a {
      border-radius: $nav-pills-border-radius;
      &:hover {
        background-color: $silver-tint;
        color: $river;
      }
      &:hover,
      &:focus {
        font-weight: bold;
        text-decoration: none;
        color: hsl(186, 89%, 24%);
        outline: 0;
      }
    }
  + li {
       margin-top: 0px;
       margin-left: 0px;
  }

  // Active state
  &.active > a {
      font-weight: bold;
      text-decoration: none;
      color: hsl(186, 89%, 24%);
      outline: 0;
      background-color:hsl(188, 26%, 89%);
    }
  }
}

// Stacked pills
.nav-stacked {
    > li {
    float: none;
      &.active {
        border-left: 0px solid $river;
        > a {
        }
        > ul {
          > li {
            &:hover,
            &:focus,
            &.active {
              transition: border .1s ease-out;
              > a {
                border-left:0;
                font-weight:normal;
              }
            }
            > a {
              font-weight:normal;
              color:$ash;
              text-indent: 10px;
            }
          }
        }
      }
    }
}

.nav-stacked {
    > li > ul {
    }
    &.right {
        > li {
            &.active {
                > a {
                  border-left: 0px solid $river;
                }
            }
        }
      > li > ul {
      border-right: 0px solid $river;
      border-left: 0px;
        > li {
            border-left: 0px;
            &:hover,
            &:focus,
            &.active {
            border-left: 0px solid $river;
            }
        }
      }
    }
}

@media (min-width: 992px) {
  .nav-stacked > li + li {
    margin-top: 2px;
    margin-left: 0px;
  }
    .nav-stacked {
        > li {
            float: none;
                > a {
                  padding: 10px 32px 10px 15px;
                  background: white inline-image('../img/left-sidebar-menu-chevron.svg') right center no-repeat;
                &:hover,
                &:focus {
                  background-color: hsl(188, 26%, 89%);
                  background-size: 32px 100%;
                }
            }
            li {
              margin-top: 0px;
              margin-left: 0; // no need for this gap between nav items
            }
            &.active > a {
                font-weight: bold;
                text-decoration: none;
                color: hsl(186, 89%, 24%);
                background: hsl(188, 26%, 89%) inline-image('../img/left-sidebar-menu-chevron.svg') right center no-repeat;
                background-size: 32px 100%;
                outline: 0;
                &:hover,
                &:focus {
                  color: hsl(186, 89%, 24%);
                  background-color: hsl(188, 26%, 89%);
                  background-size: 32px 100%;
              }
            }
        }
        &.right {
            > li > ul {
                >li {
                  > a {
                    padding: 10px 15px 10px 32px;
                    background: white inline-image('../img/right-sidebar-menu-chevron.svg') left center no-repeat;
                    &:hover,
                    &:focus {
                      background: hsl(188, 26%, 89%) inline-image('../img/right-sidebar-menu-chevron.svg') left center no-repeat;
                    }
                  }
                  &.active > a {
                      background: hsl(188, 26%, 89%) inline-image('../img/right-sidebar-menu-chevron.svg') left center no-repeat;
                  }
              }
            }
            text-align:right;
            > li {
                border-left: none;
                > a {
                  padding: 10px 15px 10px 32px;
                  background: white inline-image('../img/right-sidebar-menu-chevron.svg') left center no-repeat;
                  &:hover,
                  &:focus {
                    background: hsl(188, 26%, 89%) inline-image('../img/right-sidebar-menu-chevron.svg') left center no-repeat;
                  }
                }
                &.active > a {
                    background: hsl(188, 26%, 89%) inline-image('../img/right-sidebar-menu-chevron.svg') left center no-repeat;
                }
            }
        }

    }
}

// Nav variations
// --------------------------------------------------

// Justified nav links
// -------------------------

.nav-justified {
  width: 100%;

  > li {
    float: none;
    > a {
      text-align: center;
      margin-bottom: 5px;
    }
  }

  > .dropdown .dropdown-menu {
    top: auto;
    left: auto;
  }

  @media (min-width: $screen-sm-min) {
    > li {
      display: table-cell;
      width: 1%;
      > a {
        margin-bottom: 0;
      }
    }
  }
}

/* @import "bootstrap/navbar";
 * ========================================================================== */

/* @import "bootstrap/breadcrumbs";
 * ========================================================================== */

.breadcrumb {
    padding: 8px 15px 8px 0px;
    margin-bottom: $line-height-computed;
    list-style: none;
    background-color: $breadcrumb-bg;
    border-radius: $border-radius-base;

    > li {
        display: inline-block;

        &:first-child:before {
            content: "";
            padding: 0;
        }
        &:before,
        &:last-child:after {
            content: "/\00a0"; // Unicode space added since inline-block means non-collapsing white-space
            padding: 0 5px;
            color: $breadcrumb-color;
        }
        a:hover {
            color: $breadcrumb-hover-color;
            border-bottom: 2px solid $breadcrumb-hover-color;
            text-decoration: none;
        }
        a {
            color: $breadcrumb-color;
            font-weight: 400;
            border-bottom: 2px solid transparent;
            padding: 1px 0;
        }
    }

    > .active {
        color: $breadcrumb-active-color;
    }
}

/* @import "bootstrap/pagination";
 * ========================================================================== */
.pagination {
    > li {
        > a,
        > span {
            font-weight:bold;
        }
    }
    > li > a,
    > li > span {
        &:hover,
        &:focus {
            z-index: 2;
        }
    }
}

/* @import "bootstrap/pager";
 * ========================================================================== */
.pager {
    li {
        > a,
        > span {
            color: $pagination-color;
            font-weight: bold;
            letter-spacing: 0.5px;
        }
        > a:hover,
        > a:focus {
            color: $pager-hover-color;
        }
    }
}

/* @import "bootstrap/labels";
 * ========================================================================== */
.label {
    font-size: .7em;
    font-weight: 700;
    line-height: 1.5;
    color: $label-color;
    margin: 0 .5em;
}

/* @import "bootstrap/badges";
 * ========================================================================== */

.badge {
    background-color: $badge-bg;
    border-radius: $badge-border-radius;
    color: $badge-color;
    display: inline-block;
    font-size: $font-size-small;
    font-weight: $badge-font-weight;
    margin: 0 0 0 .5em;
    min-width: 10px;
    line-height: $badge-line-height;
    padding: 3px 7px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;

    // Empty badges collapse automatically (not available in IE8)
    &:empty {
        display: none;
    }

    // Quick fix for badges in buttons
    .btn & {
        position: relative;
        top: -1px;
    }

    .btn-xs &,
    .btn-group-xs > .btn & {
        top: 0;
        padding: 1px 5px;
    }

    // [converter] extracted a& to a.badge

    // Account for badges in navs
    .list-group-item.active > &,
    .nav-pills > .active > a > & {
        color: $badge-active-color;
        background-color: $badge-active-bg;
    }

    .list-group-item > & {
        float: right;
    }

    .list-group-item > & + & {
        margin-right: 5px;
    }

    .nav-pills > li > a > & {
        margin-left: .5em;
    }
}

/* @import "bootstrap/jumbotron";
 * ========================================================================== */

/* @import "bootstrap/thumbnails";
 * ========================================================================== */

/* @import "bootstrap/alerts";
 * ========================================================================== */
.alert-dismissable,
.alert-dismissible {
    .close {
        top: 4px;
    }
}
.alert-danger {
    @include alert-variant($alert-danger-bg, $alert-danger-border, #FFFFFF);
}

/* @import "bootstrap/progress-bars";
 * ========================================================================== */

/* @import "bootstrap/media";
 * ========================================================================== */

.media-object {
max-width:initial;
}

/* @import "bootstrap/list-group";
 * ========================================================================== */
@include list-group-item-variant(danger, $state-danger-bg, white);

/* @import "bootstrap/panels";
 * ========================================================================== */
.panel-danger {
    @include panel-variant($panel-danger-border, white, $panel-danger-heading-bg, $panel-danger-border);
}

/* @import "bootstrap/responsive-embed";
 * ========================================================================== */

/* @import "bootstrap/wells";
 * ========================================================================== */
.well {
  background-color: $silver-tint;
  border: 1px solid $silver-tint;
  box-shadow: inset 1px 1px 5px rgba(182, 190, 193, .1);
}

/* @import "bootstrap/close";
 * ========================================================================== */
.close {
    float: right;
    font-size: $font-size-base;
    font-weight: $close-font-weight;
    line-height: 1;
    color: $close-color;
    text-shadow: $close-text-shadow;
    @include opacity(.5);

    &:hover,
    &:focus {
        color: $close-color;
        text-decoration: none;
        cursor: pointer;
        @include opacity(1);
    }
}

/* Accordion Styles
*========================================================================= */
.panel-title {
  font-weight:100;
  > a,
  > small,
  > .small,
  > small > a,
  > .small > a {
    font-weight:100;
  }
}

/* ==========================================================================
 * // Components w/ JavaScript
 * ========================================================================== */

/* @import "bootstrap/modals";
 * ========================================================================== */

/* @import "bootstrap/tooltip";
 * ========================================================================== */

/* @import "bootstrap/popovers";
 * ========================================================================== */
.popover {
	padding: 0px;
	@include box-shadow(0 0 0 rgba(0, 0, 0, 0));
}
.popover-title {
	color: $popover-title-color;
}

/* @import "bootstrap/carousel";
 * ========================================================================== */

/* ==========================================================================
 * // Utility classes
 * ========================================================================== */

/* @import "bootstrap/utilities";
 * ========================================================================== */

/* @import "bootstrap/responsive-utilities";
 * ========================================================================== */
