
// Cards
$card-spacer-x:            1.25rem !default;
$card-spacer-y:            .75rem !default;
$card-border-width:        1px !default;
$card-border-radius:       0 !default;
$card-border-color:        #e5e5e5 !default;
$card-border-radius-inner: 0 !default;
$card-cap-bg:              #f5f5f5 !default;
$card-bg:                  #fff !default;

$card-link-hover-color:    #fff !default;
$enable-hover-media-query: false;
$enable-flex:              false !default;
$enable-rounded:           false;
// Grid breakpoints
//
// Define the minimum and maximum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 544px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px
) !default;

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 544px, md: 768px))
//    544px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin card-outline-variant($color) {
      background-color: transparent;
        border-color: $color;
    }

// Card variants

@mixin card-variant($background, $border) {
  background-color: $background;
  border-color: $border;
}

@mixin card-outline-variant($color) {
  background-color: transparent;
  border-color: $color;
}

@mixin hover {
  @if $enable-hover-media-query {
    // See Media Queries Level 4: http://drafts.csswg.org/mediaqueries/#hover
    // Currently shimmed by https://github.com/twbs/mq4-hover-shim
    @media (hover: hover) {
      &:hover { @content }
    }
  }
  @else {
    &:hover { @content }
  }
}

@mixin hover-focus {
  @if $enable-hover-media-query {
    &:focus { @content }
    @include hover { @content }
  }
  @else {
    &:focus,
    &:hover {
      @content
    }
  }
}

@mixin plain-hover-focus {
  @if $enable-hover-media-query {
    &,
    &:focus {
      @content
    }
    @include hover { @content }
  }
  @else {
    &,
    &:focus,
    &:hover {
      @content
    }
  }
}

@mixin hover-focus-active {
  @if $enable-hover-media-query {
    &:focus,
    &:active {
      @content
    }
    @include hover { @content }
  }
  @else {
    &:focus,
    &:active,
    &:hover {
      @content
    }
  }
}


//
// Inverse text within a card for use with dark backgrounds
//

@mixin card-inverse {
  .card-header,
  .card-footer {
    border-bottom: $card-border-width solid rgba(255,255,255,.2);
  }
  .card-header,
  .card-footer,
  .card-title,
  .card-blockquote {
    color: #fff;
  }
  .card-link,
  .card-text,
  .card-blockquote > footer {
    color: rgba(255,255,255,.65);
  }
  .card-link {
    @include hover-focus {
      color: $card-link-hover-color;
    }
  }
}

//
// Base styles
//

.card {
  position: relative;
  display: block;
  margin-bottom: $card-spacer-y;
  background-color: $card-bg;
  border: $card-border-width solid $card-border-color;
}

.card-block {
  padding: $card-spacer-x;
}

.card-block > :first-child {
  margin-top: 0;
}

.card-block > :last-child {
  margin-bottom: 0;
}

.card-title {
  margin-bottom: $card-spacer-y;
  font-weight: $headings-font-weight-medium;
}

.card-subtitle {
  margin-top: -($card-spacer-y / 2);
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

a.card {
  font-weight: normal;
  color: $ash;
  &:focus,&:hover {
      text-decoration: none;
  }
  .card-link {
   font-weight:bold;
   color:$red30b;
  }
}
.card-landing-grid {
    cursor: pointer;
    border-right: $sky 1px solid;
    border-bottom: $sky 1px solid;
    border-top-color:transparent;
    border-left-color:transparent;
    padding: 2.5rem;
    &:focus, &:hover {
      transition: .3s;
      background-color: #D0EEF4;
    }
}

// .card-actions {
//   padding: $card-spacer-y $card-spacer-x;

//   .card-link + .card-link {
//     margin-left: $card-spacer-x;
//   }
// }

.card-link {
  @include hover {
    /*text-decoration: none;*/
  }

  + .card-link {
    margin-left: $card-spacer-x;
  }
}



//
// Optional textual caps
//

.card-header {
  padding: $card-spacer-y $card-spacer-x;
  background-color: $card-cap-bg;
  border-bottom: $card-border-width solid $card-border-color;

  &:first-child {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.card-footer {
  padding: $card-spacer-y $card-spacer-x;
  background-color: $card-cap-bg;
  border-top: $card-border-width solid $card-border-color;

  &:last-child {
    margin-top: 0;
    margin-bottom: 0;
  }
}


//
// Background variations
//

.card-primary {
  @include card-variant($brand-primary, $brand-primary);
}
.card-success {
  @include card-variant($brand-success, $brand-success);
}
.card-info {
  @include card-variant($brand-info, $brand-info);
}
.card-warning {
  @include card-variant($brand-warning, $brand-warning);
}
.card-danger {
  @include card-variant($brand-danger, $brand-danger);
}

// Remove all backgrounds
.card-primary-outline {
  @include card-outline-variant($btn-primary-bg);
}
.card-info-outline {
  @include card-outline-variant($btn-info-bg);
}
.card-success-outline {
  @include card-outline-variant($btn-success-bg);
}
.card-warning-outline {
  @include card-outline-variant($btn-warning-bg);
}
.card-danger-outline {
  @include card-outline-variant($btn-danger-bg);
}


//
// Inverse text within a card for use with dark backgrounds
//

.card-inverse {
  @include card-inverse;
}

//
// Blockquote
//

.card-blockquote {
  padding: 0;
  margin-bottom: 0;
  border-left: 0;
}

// Card image
.card-img {
  width:100%;
  height:auto;
  // margin: -1.325rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

// Image with caption

.card-img-caption {
  position: absolute;
  background: rgba(51, 51, 51, .6);
  right: 0;
  bottom: 0;
  left: 0;
  padding: .5rem 1rem;
}

.card-inverse a {
  color: #fff;
}

// Card image caps
.card-img-top {
}
.card-img-bottom {
}


//
// Card deck
//

@if $enable-flex {
  @include media-breakpoint-up(sm) {
    .card-deck {
      display: flex;
      flex-flow: row wrap;
      margin-right: -.625rem;
      margin-left: -.625rem;

      .card {
        flex: 1 0 0;
        margin-right: .625rem;
        margin-left: .625rem;
      }
    }
  }
} @else {
  @include media-breakpoint-up(sm) {
    .card-deck {
      display: table;
      table-layout: fixed;
      border-spacing: 1.25rem 0;
      vertical-align: top;
      min-width: 1%;
      width: 100%;
      height: 1%;

      .card {
        display: table-cell;
        height: inherit;
        vertical-align: top;
        width: 33%;
        max-width: 33%;
      }
    }
    .card-deck-wrapper {
      margin-right: -1.25rem;
      margin-left: -1.25rem;
    }
  }
}

//
// Card groups
//

@include media-breakpoint-up(sm) {
  .card-group {
    @if $enable-flex {
      display: flex;
      flex-flow: row wrap;
    } @else {
      display: table;
      width: 100%;
      table-layout: fixed;
      height:1%;
    }

    .card {
      @if $enable-flex {
        flex: 1 0 0;
      } @else {
        display: table-cell;
        vertical-align: top;
        height:inherit;
      }

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {
        &:first-child {

          .card-img-top {
            border-top-right-radius: 0;
          }
          .card-img-bottom {
            border-bottom-right-radius: 0;
          }
        }
        &:last-child {
          @include border-left-radius(0);

          .card-img-top {
            border-top-left-radius: 0;
          }
          .card-img-bottom {
            border-bottom-left-radius: 0;
          }
        }

        &:not(:first-child):not(:last-child) {
          border-radius: 0;

          .card-img-top,
          .card-img-bottom {
            border-radius: 0;
          }
        }
      }
    }
  }
}

//
// Card
//

@include media-breakpoint-up(sm) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;

    .card {
      width: 100%; // Don't let them exceed the column width
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
      page-break-after:always;
      -webkit-column-break-after: always;
      break-after: always;

      &:first-of-type:last-of-type {
        display: inline-block;
      }
    }
  }
}