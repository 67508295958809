@import 'ua-colors-map';

/*
 *  Example usage:
 *
 *  p {
 *      color: ua-color('red', '30w');
 *  }
 *  button {
 *      background-color: ua-color('cactus');
 *      color: ua-color('cactus', 'light');
 *  }
 */

@function ua-color($color-name, $color-variant: 'default') {
  $color: map-get(map-get($ua-colors, $color-name), $color-variant);
  @if $color {
    @return $color;
  } @else {
    // Libsass still doesn't seem to support @error
    @warn "=> ERROR: COLOR NOT FOUND! <= | Your $color-name, $color-variant combination did not match any of the values in the $material-colors map.";
  }
}
