
// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
//@import url(https://cdn.uadigital.arizona.edu/lib/ua-brand-fonts/1.0.0/milo.min.css);

// Variables
//@import "variables";

// UA Bootstrap
@import "vendor/ua-bootstrap";

// datetime picker
@import "node_modules/eonasdan-bootstrap-datetimepicker/src/sass/bootstrap-datetimepicker";

// summernote
@import "node_modules/summernote/dist/summernote";

#header_site {
  margin-bottom: 2em;
}

.columns {
  @media (min-width: $screen-sm-min) {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;

    -webkit-column-gap: 4em;
    -moz-column-gap: 4em;
    column-gap: 4em;
  }
}

.no-break {
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
  page-break-inside: avoid;

  padding-top: 2em;

  h3 {
    margin-top: 0;
  }
}

table {
  input[type=number] {
    width: 45px;
  }

  .panel & {
    margin-bottom: 0;
  }
}

.table {
  &-condensed {
    font-size: .9em;

    tfoot tr td {
      border-top: 1px solid $table-bg-accent;
    }
  }

  &-funding.table-striped {
    > tbody > tr:nth-of-type(2n+1) {
      background-color: #FFF;
    }

    > tbody > tr:nth-of-type(2n+2):hover {
      background-color: #FFF;
      cursor: inherit;
      color: inherit;
    }

    > tbody > tr:nth-of-type(4n+1) {
      background-color: $table-bg-accent;
    }
  }
}

.dl-horizontal dt {
  white-space: normal;
}

$dt-width: 200px;
@media (min-width: $screen-sm-min) {
  .dl-horizontal {
    dt {
      width: $dt-width;
      font-size: .9em;
    }
    dd {
      margin-left: $dt-width + 20;
    }
  }
}

// animations
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}

// misc
.btn-link {
  padding: 0;
  border: 0;
}

.left-indent {
  margin-left: 2em;
}

// datetime picker
.bootstrap-datetimepicker-widget {
  background-color: #FFF!important;

  &::before {
    display: none!important;
  }
}

// summernote
.note-toolbar-wrapper {
  .btn {
    height: 35px;
    background-color: $blue30w;
    border-color: $blue30w;

    &:hover {
      background-color: darken($blue30w, 15%);
      border-color: darken($blue30w, 15%);
    }
  }
}

// nav dropdown
.nav {
  .dropdown {
    &-toggle {
      position: absolute!important;
      right: 0;
      top: 0;

      background-color: lighten($blue60w, 15%);
      color: #FFF;
      cursor: pointer;

      &:hover {
        background-color: $blue60w!important;
      }
    }
  }
}

.horizontal-reset{
  .form-group {
    margin: 0;
  }

  .control-label {
    text-align: left;
  }
}
